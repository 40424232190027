import {FormGroup, H2, InputGroup, Intent, Spinner} from '@blueprintjs/core';
import {ItemListPredicate} from '@blueprintjs/select';
import {Cell, MultiSelect, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {IConstraintViolations, isConstraintViolationError} from '../../../Api/Error';
import {Account, AccountModel} from '../../../Api/Models/Account';
import {PodModel} from '../../../Api/Models/Pod';
import {toaster} from '../../../toaster';
import {ValidationAwareFormGroup} from '../../ValidationAwareFormGroup';
import {accountSorter} from '../Accounts/AccountList';
import {BreadcrumbItems, EditorBreadcrumbs} from '../EditorBreadcrumbs';
import {EditorButtons} from '../EditorButtons';

interface IRouteProps {
	pod: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
}

interface IState {
	accounts: Account[];
	allAccounts: Account[];
	loading: boolean;
	name: string;
	redirect: boolean;
	saving: boolean;
	violations: IConstraintViolations;
}

class PodEditorComponent extends React.PureComponent<IProps, IState> {
	public state: Readonly<IState> = {
		accounts: [],
		allAccounts: null,
		loading: true,
		name: '',
		redirect: false,
		saving: false,
		violations: {},
	};

	public componentDidMount(): void {
		const allAccountsPromise = AccountModel.list(null, {
			id: true,
			name: true,
		}).then(response => {
			const allAccounts = response.data.sort(accountSorter);

			this.setState({
				allAccounts,
			});

			return allAccounts;
		});

		const idParam = this.props.match.params.pod;

		if (idParam === 'new') {
			this.setState({
				loading: false,
			});

			return;
		}

		PodModel.read(idParam, {
			'accounts.id': true,
			'accounts.name': true,
			id: true,
			name: true,
		}).then(response => {
			this.setState({
				loading: false,
				name: response.data.name,
			});

			allAccountsPromise.then(allAccounts => {
				const accountIds = response.data.accounts.map(account => account.id);
				const accounts: Account[] = [];

				for (const account of allAccounts) {
					if (accountIds.indexOf(account.id) !== -1)
						accounts.push(account);
				}

				this.setState({
					accounts,
				});
			});
		});
	}

	public render(): React.ReactNode {
		if (this.state.loading)
			return <Spinner intent={Intent.PRIMARY} />;
		else if (this.state.redirect)
			return <Redirect to="/edit/pods" />;

		return (
			<>
				<EditorBreadcrumbs
					items={[
						BreadcrumbItems.pods(),
						{
							text: this.state.name || 'No Name',
						},
					]}
				/>

				<H2>{this.state.name || 'No Name'}</H2>

				<form onSubmit={this.onSave}>
					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup label="Name" labelFor="name" violations={this.state.violations}>
								<InputGroup name="name" onChange={this.onNameChange} value={this.state.name} />
							</ValidationAwareFormGroup>
						</Cell>
					</Row>

					<FormGroup label="Accounts">
						<MultiSelect
							itemListPredicate={this.onAccountListFilter}
							items={this.state.allAccounts || this.state.accounts}
							itemTextRenderer={this.renderAccountText}
							loading={!this.state.allAccounts && !this.state.accounts}
							onClear={this.onAccountsClear}
							onItemDeselect={this.onAccountDeselect}
							onItemSelect={this.onAccountSelect}
							popoverProps={{
								targetClassName: 'full-width',
							}}
							selected={this.state.accounts}
							virtual={true}
						/>
					</FormGroup>

					<EditorButtons onClose={this.onCancelClick} onSave={this.onSave} saving={this.state.saving} />
				</form>
			</>
		);
	}

	private renderAccountText = (account: Account) => account.name;

	private onAccountsClear = () => this.setState({
		accounts: [],
	});

	private onAccountDeselect = (target: Account) => this.setState({
		accounts: this.state.accounts.filter(account => account !== target),
	});

	private onAccountSelect = (account: Account) => this.setState({
		accounts: [...this.state.accounts, account],
	});

	private onAccountListFilter: ItemListPredicate<Account> = (query, items) => {
		query = query.toLowerCase();

		return items.filter(item => item.name.toLowerCase().indexOf(query) !== -1);
	};

	private onCancelClick = () => this.setState({
		redirect: true,
	});

	private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		name: event.currentTarget.value,
	});

	private onSave = (event?: React.SyntheticEvent<any>) => {
		if (event)
			event.preventDefault();

		if (this.state.saving)
			return;

		this.setState({
			saving: true,
		});

		const payload = {
			accounts: this.state.accounts.map(account => account.id),
			name: this.state.name,
		};

		const idParam = this.props.match.params.pod;
		let promise: Promise<unknown>;

		if (idParam === 'new')
			promise = PodModel.create(payload, {id: true});
		else
			promise = PodModel.update(idParam, payload, {id: true});

		promise.then(() => {
			toaster.show({
				intent: Intent.SUCCESS,
				message: `${this.state.name} ${idParam === 'new' ? 'created' : 'updated'}.`,
			});

			this.setState({
				redirect: true,
			});
		}).catch((error: Error) => {
			toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			if (isConstraintViolationError(error)) {
				this.setState({
					violations: error.context.violations,
				});
			}

			this.setState({
				saving: false,
			});
		});
	};
}

export const PodEditor = withRouter(PodEditorComponent);
