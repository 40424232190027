import * as React from 'react';
import {Route} from 'react-router';
import {Switch} from '../../Navigation/Switch';
import {Integrations} from '../Integrations';
import {AccountEditor} from './AccountEditor';
import {AccountList} from './AccountList';

export const Accounts: React.FC<{}> = () => (
	<Switch>
		<Route path="/edit/accounts" exact={true} component={AccountList} />
		<Route path="/edit/accounts/:account(\d+|new)" exact={true} component={AccountEditor} />

		<Route path="/edit/accounts/:account(\d+)/integrations" component={Integrations} />
	</Switch>
);
