import {Intent} from '@blueprintjs/core';
import * as React from 'react';
import {Pod, PodModel} from '../../../Api/Models/Pod';
import {toaster} from '../../../toaster';
import {createEntitySorter, EntityList} from '../EntityList';

export const podSorter = createEntitySorter<Pod>('name');

interface IState {
	loading: boolean;
	pods: Pod[];
}

const PodEntityList = EntityList.ofType<Pod>();

export class PodList extends React.PureComponent<{}, IState> {
	public state: Readonly<IState> = {
		loading: false,
		pods: [],
	};

	public componentDidMount(): void {
		this.load();
	}

	public render(): React.ReactNode {
		return (
			<PodEntityList
				basePath="/edit/pods"
				columns={[
					{
						dataIndex: 'name',
						title: 'Name',
					},
					{
						render: pod => `${pod.accounts.length} Account${pod.accounts.length !== 1 ? 's' : ''}`,
						title: 'Accounts',
					},
				]}
				entities={this.state.pods}
				loading={this.state.loading}
				noDataPlaceholder="No pods found."
				onDelete={this.onPodDelete}
				onRefresh={this.load}
				title="Pods"
			/>
		);
	}

	private onPodDelete = (target: Pod) => PodModel.delete(target.id).then(() => this.setState({
		pods: this.state.pods.filter(pod => pod !== target),
	})).catch((error: Error) => {
		toaster.show({
			intent: Intent.DANGER,
			message: error.message,
		});
	});

	private load = () => {
		if (this.state.loading)
			return;

		this.setState({
			loading: true,
		});

		PodModel.list(null, {
			'accounts.id': true,
			id: true,
			name: true,
		}).then(response => this.setState({
			loading: false,
			pods: response.data.sort(podSorter),
		}));
	};
}
