import {CancelToken} from 'axios';
import {client} from '../client';

interface IGoogleAnalyticsProfile {
	id: string;
	name: string;
}

export type GoogleAnalyticsProfile = Partial<IGoogleAnalyticsProfile>;

export class GoogleAnalyticsModel {
	public static getProfiles(cancelToken?: CancelToken) {
		return client.get('/google-analytics/accounts', {
			cancelToken,
		});
	}
}
