import {CancelToken} from 'axios';
import {Omit} from 'utility-types';
import {Role} from '../../Components/RequireRole';
import {client} from '../client';
import {Id, IEntity} from '../Model';
import {IQueryDocument, Projection} from '../routes';

interface IUser extends IEntity {
	email: string;
	firstName: string;
	lastName: string;
	roles: Role[];
}

export type User = Partial<IUser>;
export type UserPayload = Omit<IUser, 'id'> & {
	activationUrl?: string;
};

export class UserModel {
	public static list(query?: IQueryDocument, projection?: Projection, cancelToken?: CancelToken) {
		return client.get('/users', {
			cancelToken,
			params: {
				p: projection,
				q: query,
			},
		}).then(response => {
			response.data = response.data.map(UserModel.denormalize);

			return response;
		});
	}

	public static create(data: UserPayload, projection?: Projection) {
		return client.put('/users', data, {
			params: {
				p: projection,
			},
		}).then(response => {
			response.data = UserModel.denormalize(response.data);

			return response;
		});
	}

	public static read(id: Id, projection?: Projection, cancelToken?: CancelToken) {
		return client.get<'/users/:id'>(`/users/${id}`, {
			cancelToken,
			params: {
				p: projection,
			},
		}).then(response => {
			response.data = UserModel.denormalize(response.data);

			return response;
		});
	}

	public static update(id: Id, data: UserPayload, projection?: Projection) {
		return client.patch<'/users/:id'>(`/users/${id}`, data, {
			params: {
				p: projection,
			},
		}).then(response => {
			response.data = UserModel.denormalize(response.data);

			return response;
		});
	}

	public static delete(id: Id) {
		return client.delete<'/users/:id'>(`/users/${id}`);
	}

	public static sendPasswordResetCode(email: string) {
		return client.post('/users/password-reset', {
			email,
			passwordResetUrl: `${window.location.protocol}//${window.location.host}/password-reset/:code`,
		});
	}

	public static resetPassword(code: string, password: string) {
		return client.post<'/users/password-reset/:code'>(`/users/password-reset/${code}`, {
			password,
		});
	}

	public static activate(code: string, password: string) {
		return client.post<'/users/activate/:code'>(`/users/activate/${code}`, {
			password,
		});
	}

	protected static denormalize(user: User): User {
		if (typeof user.firstName !== 'undefined' && !user.firstName)
			user.firstName = '';

		if (typeof user.lastName !== 'undefined' && !user.lastName)
			user.lastName = '';

		return user;
	}
}
