import {Alignment, Button, Navbar, NavbarDivider, NavbarGroup} from '@blueprintjs/core';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {LogoutHandler} from '../Auth/LogoutHandler';
import {LinkButton} from './LinkButton';

export const Navigation: React.FC<{}> = () => (
	<Navbar fixedToTop={true}>
		<NavbarGroup align={Alignment.LEFT}>
			<Link to="/" className="plain-link">
				Supersheet
			</Link>

			<NavbarDivider />

			<LinkButton to="/edit/accounts" buttonProps={{minimal: true}}>
				Accounts
			</LinkButton>

			<LinkButton to="/edit/pods" buttonProps={{minimal: true}}>
				Pods
			</LinkButton>

			<LinkButton to="/edit/users" buttonProps={{minimal: true}}>
				Users
			</LinkButton>
		</NavbarGroup>

		<NavbarGroup align={Alignment.RIGHT}>
			<NavbarDivider />

			<LogoutHandler>
				<Button minimal={true}>
					Logout
				</Button>
			</LogoutHandler>
		</NavbarGroup>
	</Navbar>
);
