import {Intent, Spinner} from '@blueprintjs/core';
import Axios, {CancelTokenSource} from 'axios';
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {CancelReason} from '../../../Api/client';
import {AccountModel} from '../../../Api/Models/Account';
import {Integration, IntegrationModel, integrationsNames} from '../../../Api/Models/Integration';
import {createEntityFilter, createEntitySorter} from '../EntityList';
import {BreadcrumbItems, EditorBreadcrumbs} from '../EditorBreadcrumbs';
import {EntityList} from '../EntityList';

const sorter = createEntitySorter<Integration>('name');
const filterEntityOnName = createEntityFilter<Integration>('type');

interface IRouteProps {
	account: string;
}

interface IIntegrationListProps extends RouteComponentProps<IRouteProps> {
}

interface IState {
	accountName: string;
	integrations: Integration[];
	loading: boolean;
}

const IntegrationEntityList = EntityList.ofType<Integration>();

export class IntegrationListComponent extends React.PureComponent<IIntegrationListProps, IState> {
	public state: Readonly<IState> = {
		accountName: null,
		integrations: [],
		loading: false,
	};

	private cancelSource: CancelTokenSource = null;

	public componentDidMount(): void {
		this.load();
	}

	public componentWillUnmount(): void {
		if (this.cancelSource)
			this.cancelSource.cancel(CancelReason.UNMOUNT);
	}

	public render(): React.ReactNode {
		const accountId = this.props.match.params.account;
		const accountBreadcrumb = this.state.accountName ?
			BreadcrumbItems.account(accountId, this.state.accountName) :
			{
				text: <Spinner intent={Intent.PRIMARY} size={16} />,
			};

		return (
			<>
				<EditorBreadcrumbs
					items={[
						BreadcrumbItems.accounts(),
						accountBreadcrumb,
						BreadcrumbItems.integrations(this.props.match.params.account),
					]}
				/>

				<IntegrationEntityList
					basePath={`/edit/accounts/${accountId}/integrations`}
					columns={[
						{
							dataIndex: 'name',
							onFilter: filterEntityOnName,
							title: 'Name',
						},
						{
							onFilter: filterEntityOnName,
							render: integration => integrationsNames[integration.type],
							title: 'Type',
						},
					]}
					entities={this.state.integrations}
					loading={this.state.loading}
					noDataPlaceholder="No integrations found."
					onDelete={this.onIntegrationDelete}
					onRefresh={this.load}
					title="Integrations"
				/>
			</>
		);
	}

	private onIntegrationDelete = (target: Integration) => {
		return IntegrationModel.delete(target.id).then(() => this.setState({
			integrations: this.state.integrations.filter(integration => integration !== target),
		}));
	};

	private load = () => {
		if (this.state.loading)
			return;

		this.setState({
			loading: true,
		});

		this.cancelSource = Axios.CancelToken.source();

		IntegrationModel.list({
			'account.id': this.props.match.params.account,
		}, {
			id: true,
			name: true,
			type: true,
		}, this.cancelSource.token).then(response => {
			this.setState({
				integrations: response.data.sort(sorter),
				loading: false,
			});

			this.cancelSource = null;
		});

		if (!this.state.accountName) {
			AccountModel.read(this.props.match.params.account, {
				name: true,
			}, this.cancelSource.token).then(response => this.setState({
				accountName: response.data.name,
			}));
		}
	};
}

export const IntegrationList = withRouter(IntegrationListComponent);
