import Axios, {CancelTokenSource} from 'axios';
import * as React from 'react';
import {CancelReason} from '../../../Api/client';
import {User, UserModel} from '../../../Api/Models/User';
import {createEntityFilter, EntityList} from '../EntityList';

const sorter = (a: User, b: User) => {
	const aName = `${a.firstName} ${a.lastName}`.trim();
	const bName = `${b.firstName} ${b.lastName}`.trim();

	return aName.localeCompare(bName);
};
const filterEntityOnEmail = createEntityFilter<User>('email');

interface IState {
	loading: boolean;
	users: User[];
}

const UserEntityList = EntityList.ofType<User>();

export class UserList extends React.PureComponent<{}, IState> {
	public state: Readonly<IState> = {
		loading: false,
		users: [],
	};

	private cancelSource: CancelTokenSource = null;

	public componentDidMount(): void {
		this.load();
	}

	public componentWillUnmount(): void {
		if (this.cancelSource)
			this.cancelSource.cancel(CancelReason.UNMOUNT);
	}

	public render(): React.ReactNode {
		return (
			<UserEntityList
				basePath="/edit/users"
				columns={[
					{
						render: user => `${user.firstName} ${user.lastName}`,
						title: 'Name',
					},
					{
						dataIndex: 'email',
						onFilter: filterEntityOnEmail,
						title: 'Email Address',
					},
				]}
				entities={this.state.users}
				loading={this.state.loading}
				noDataPlaceholder="No users found."
				onDelete={this.onUserDelete}
				onRefresh={this.load}
				title="Users"
			/>
		);
	}

	private onUserDelete = (target: User) => {
		return UserModel.delete(target.id).then(() => this.setState({
			users: this.state.users.filter(user => user !== target),
		}));
	};

	private load = () => {
		if (this.state.loading)
			return;

		this.setState({
			loading: true,
		});

		this.cancelSource = Axios.CancelToken.source();

		UserModel.list(null, {
			email: true,
			firstName: true,
			id: true,
			lastName: true,
			name: true,
		}, this.cancelSource.token).then(response => {
			this.setState({
				users: response.data.sort(sorter),
				loading: false,
			});

			this.cancelSource = null;
		});
	};
}
