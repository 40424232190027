import {Button, FormGroup, Intent, Menu, MenuItem, Popover, Position, Tooltip} from '@blueprintjs/core';
import {ItemListPredicate} from '@blueprintjs/select';
import {Cell, MultiSelect, Row} from '@dbstudios/blueprintjs-components';
import Axios, {CancelTokenSource} from 'axios';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {CancelReason} from '../../../Api/client';
import {Account, AccountModel} from '../../../Api/Models/Account';
import {Pod, PodModel} from '../../../Api/Models/Pod';
import {Sheet} from '../../../Api/Models/Sheet';
import {toaster} from '../../../toaster';
import {plural} from '../../../Utility/language';
import {IThemeAware, withTheme} from '../../Contexts/ThemeContext';
import {createEntityFilter, createEntitySorter, EntityList} from '../EntityList';
import {podSorter} from '../Pods/PodList';
import {SyncDialog} from './SyncDialog';

import * as ReactGA from 'react-ga';

export const accountSorter = createEntitySorter<Account>('name');

const sheetSorter = (a: Sheet, b: Sheet) => {
	if (a.year === b.year)
		return 0;
	else if (a.year > b.year)
		return -1;

	return 1;
};

const filterEntityOnName = createEntityFilter<Account>('name');

const amazonPath = `${process.env.AMAZON_S3_URL}${process.env.AMAZON_S3_URL.substr(-1) !== '/' ? '/' : ''}`;

interface IState {
	accounts: Account[];
	loading: boolean;
	pods: Pod[];
	selectedPods: Pod[];
	syncDialogActiveAccount: Account;
}

const AccountEntityList = EntityList.ofType<Account>();

class AccountListComponent extends React.PureComponent<IThemeAware, IState> {
	public state: Readonly<IState> = {
		accounts: [],
		loading: false,
		pods: null,
		selectedPods: [],
		syncDialogActiveAccount: null,
	};

	private cancelSource: CancelTokenSource = null;

	public componentDidMount(): void {
		this.load();
	}

	public componentWillUnmount(): void {
		if (this.cancelSource)
			this.cancelSource.cancel(CancelReason.UNMOUNT);

		toaster.dismiss('unassigned');
	}

	public render(): React.ReactNode {
		let accounts: Account[] = this.state.accounts;

		if (this.state.selectedPods.length > 0) {
			const selectedPodIds = this.state.selectedPods.map(pod => pod.id);

			accounts = accounts.filter(account => account.pods.find(pod => selectedPodIds.indexOf(pod.id) !== -1));
		}

		return (
			<>
				<AccountEntityList
					basePath="/edit/accounts"
					columns={[
						{
							dataIndex: 'name',
							onFilter: filterEntityOnName,
							title: 'Name',
						},
						{
							render: record => (
								<Link to={`/edit/accounts/${record.id}/integrations`}>
									{record.integrations.length} Integration{record.integrations.length !== 1 ? 's' : ''}
								</Link>
							),
							title: 'Integrations',
						},
						{
							render: account => account.pods.map(pod => pod.name).join(', ') || <span>&mdash;</span>,
							title: 'Pods',
						},
					]}
					entities={accounts}
					extraControls={(
						<Row>
							<Cell size={5}>
								{this.state.pods !== null && (
									<FormGroup label="Filter Pods">
										<MultiSelect
											itemListPredicate={this.onPodListFilter}
											items={this.state.pods}
											itemTextRenderer={this.renderPodText}
											onClear={this.onPodsClear}
											onItemDeselect={this.onPodDeselect}
											onItemSelect={this.onPodSelect}
											popoverProps={{
												targetClassName: 'full-width',
											}}
											selected={this.state.selectedPods}
										/>
									</FormGroup>
								)}
							</Cell>
						</Row>
					)}
					loading={this.state.loading}
					noDataPlaceholder="No accounts found."
					onDelete={this.onAccountDelete}
					onRefresh={this.load}
					rowControls={record => (
						<>
							<Tooltip
								content={this.renderSyncTooltip(record)}
								disabled={record.syncs.length === 0}
							>
								<Button
									disabled={record.syncs.length > 0 || record.integrations.length === 0}
									minimal={true}
									onClick={() => this.onSyncClick(record)}
								>
									Sync
								</Button>
							</Tooltip>

							<Popover position={Position.RIGHT}>
								<Button
									type="button"
									minimal={true}
									small={true}
								>
									Download
								</Button>

								<Menu>
									{record.sheets.length > 0 && record.sheets.map(sheet => (
										<MenuItem
											href={`${amazonPath}${sheet.file}?t=${Date.now()}`}
											icon="cloud-download"
											intent={Intent.PRIMARY}
											key={sheet.year}
											onClick={() => this.onSheetDownloadClick(sheet)}
											target="_blank"
											text={sheet.year}
										/>
									)) || <MenuItem text="No sheets available" />}
								</Menu>
							</Popover>
						</>
					)}
					rowControlsWidth={280}
					title="Accounts"
				/>

				<SyncDialog
					account={this.state.syncDialogActiveAccount}
					isOpen={this.state.syncDialogActiveAccount !== null}
					onClose={this.onSyncDialogHide}
					onSubmit={this.onSyncDialogSubmit}
				/>
			</>
		);
	}

	private renderSyncTooltip = (account: Account): string | JSX.Element => {
		if (account.syncs.length === 0)
			return '';

		const sync = account.syncs[0];
		let status: string = 'Unknown';

		if (sync.progressMax > 0) {
			const rate = sync.progress / sync.progressMax;

			if (rate < 1)
				status = `${Math.floor(rate * 100)}%`;
			else
				status = 'Uploading';
		} else
			status = 'Queued';

		return (
			<>
				<p>
					{sync.year} sync in progres...
				</p>

				<p style={{marginBottom: 0}}>
					Status: {status}
				</p>
			</>
		);
	};

	private renderPodText = (pod: Pod) => pod.name;

	private onAccountDelete = (target: Account) => {
		return AccountModel.delete(target.id).then(() => {
			this.setState({
				accounts: this.state.accounts.filter(account => account !== target),
			});

			toaster.show({
				intent: Intent.SUCCESS,
				message: `${target.name} has been deleted.`,
			});
		});
	};

	private onPodsClear = () => this.setState({
		selectedPods: [],
	});

	private onPodDeselect = (target: Pod) => this.setState({
		selectedPods: this.state.selectedPods.filter(pod => pod !== target),
	});

	private onPodSelect = (pod: Pod) => this.setState({
		selectedPods: [...this.state.selectedPods, pod],
	});

	private onPodListFilter: ItemListPredicate<Pod> = (query, items) => {
		query = query.toLowerCase();

		return items.filter(item => item.name.toLowerCase().indexOf(query) !== -1);
	};

	private onSyncClick = (account: Account) => this.setState({
		syncDialogActiveAccount: account,
	});

	private onSyncDialogHide = () => this.setState({
		syncDialogActiveAccount: null,
	});

	private onSyncDialogSubmit = (account: Account, year: number) => {
		return AccountModel.sync(account.id, year).then(response => {
			account.syncs.push(response.data);

			this.setState({
				accounts: [...this.state.accounts],
				syncDialogActiveAccount: null,
			});
		});
	};

	private load = () => {
		if (this.state.loading)
			return;

		this.cancelSource = Axios.CancelToken.source();

		this.setState({
			loading: true,
			pods: null,
		});

		toaster.dismiss('unassigned');

		Promise.all([
			PodModel.list(null, {
				id: true,
				name: true,
			}, this.cancelSource.token).then(response => this.setState({
				pods: response.data.sort(podSorter),
			})),
			AccountModel.list(null, null, this.cancelSource.token).then(response => {
				this.setState({
					accounts: response.data.sort(accountSorter).map(account => {
						account.sheets = account.sheets.sort(sheetSorter);

						return account;
					}),
					loading: false,
				});

				const unassigned = response.data.reduce((prev, account) => {
					if (account.pods.length === 0)
						++prev;

					return prev;
				}, 0);

				if (unassigned > 0) {
					toaster.show({
						intent: Intent.WARNING,
						message: plural(unassigned, [
							'There is :count account that needs to be assigned to a pod.',
							'There are :count accounts that need to be assigned to a pod.',
						]),
						timeout: 0,
					}, 'unassigned');
				}
			}),
		]).then(() => this.cancelSource = null);
	};

	private onSheetDownloadClick = (target: Sheet) => {
		const account = this.state.accounts.find(item => (item.id === target.account));

		ReactGA.event({
			action: 'click',
			category: 'Download Spreadsheet',
			label: `${account.name} - ${target.file}`,
		});
	};
}

export const AccountList = withTheme(AccountListComponent);
