import {FormGroup} from '@blueprintjs/core';
import {Classes} from '@blueprintjs/select';
import {Cell, Row, Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {GoogleAnalyticsModel, GoogleAnalyticsProfile} from '../../../../Api/Models/GoogleAnalytics';
import {IGoogleAnalyticsIntegrationOptions} from '../../../../Api/Models/Integration';
import {createEntitySorter} from '../../EntityList';

const profileSorter = createEntitySorter<GoogleAnalyticsProfile>('name');

interface IProps {
	options: IGoogleAnalyticsIntegrationOptions;
	onChange: (options: IGoogleAnalyticsIntegrationOptions) => void;
}

interface IState {
	profile: GoogleAnalyticsProfile;
	profileOptions: GoogleAnalyticsProfile[];
}

export class GoogleAnalyticsOptionsEditor extends React.PureComponent<IProps, IState> {
	public state: Readonly<IState> = {
		profile: null,
		profileOptions: null,
	};

	public componentDidMount(): void {
		GoogleAnalyticsModel.getProfiles().then(response => this.setState({
			profile: this.findSelectedProfile(response.data),
			profileOptions: response.data.sort(profileSorter),
		}));
	}

	public componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (this.props.options === prevProps.options)
			return;

		this.setState({
			profile: this.findSelectedProfile(),
		});
	}

	public render(): React.ReactNode {
		return (
			<>
				<Row>
					<Cell size={6}>
						<FormGroup label="Google Analytics Account" labelFor="profileId">
							<Select
								itemKey="id"
								itemListPredicate={this.onProfileFilter}
								items={this.state.profileOptions}
								itemTextRenderer={this.renderProfileText}
								loading={this.state.profileOptions === null}
								onItemSelect={this.onProfileSelect}
								popoverProps={{
									fill: true,
									modifiers: {
										minwidth: {
											enabled: true,
											fn: data => {
												data.styles.width = `${data.offsets.reference.width}px`;
												return data;
											},
											order: 800,
										},
									},
									popoverClassName: `${Classes.SELECT}-match-target-width`,
									targetClassName: 'full-width',
									usePortal: false,
									wrapperTagName: 'div',
								}}
								selected={this.state.profile}
								virtual={true}
							/>
						</FormGroup>
					</Cell>
				</Row>
			</>
		);
	}

	private renderProfileText = (profile: GoogleAnalyticsProfile) => profile.name;

	private onProfileFilter = (query: string, profiles: GoogleAnalyticsProfile[]) => {
		query = query.toLowerCase();

		return profiles.filter(profile => profile.name.toLowerCase().indexOf(query) !== -1);
	};

	private onProfileSelect = (profile: GoogleAnalyticsProfile) => {
		this.setState({
			profile,
		});

		this.props.onChange({
			profileId: profile.id,
		});
	};

	private findSelectedProfile = (profiles?: GoogleAnalyticsProfile[]) => {
		if (!this.props.options.profileId)
			return null;

		return (profiles || this.state.profileOptions || [])
			.find(profile => profile.id === this.props.options.profileId) || null;
	};
}
