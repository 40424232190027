import {CancelToken} from 'axios';
import {client} from '../client';

interface ICallCommandoAccount {
	apiKey: string;
	name: string;
}

export type CallCommandoAccount = Partial<ICallCommandoAccount>;

export class CallCommandoModel {
	public static getAccounts(cancelToken?: CancelToken) {
		return client.get('/call-commando/accounts', {
			cancelToken,
		});
	}
}
