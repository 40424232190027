import * as React from 'react';
import {Route} from 'react-router';
import {Switch} from '../../Navigation/Switch';
import {IntegrationEditor} from './IntegrationEditor';
import {IntegrationList} from './IntegrationList';

export const Integrations: React.FC<{}> = () => (
	<Switch>
		<Route path="/edit/accounts/:account(\d+)/integrations" exact={true} component={IntegrationList}/>
		<Route
			path="/edit/accounts/:account(\d+)/integrations/:integration(\d+|new)"
			exact={true}
			component={IntegrationEditor}
		/>
	</Switch>
);
