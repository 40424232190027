import {CancelToken} from 'axios';
import {Omit} from 'utility-types';
import {client} from '../client';
import {Id, IEntity} from '../Model';
import {IQueryDocument, Projection} from '../routes';

export enum IntegrationType {
	ADWORDS = 'ADWORDS',
	CALL_COMMANDO = 'CALL_COMMANDO',
	FACEBOOK = 'FACEBOOK',
	GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
}

export const integrationsNames: { [key in IntegrationType]: string } = {
	[IntegrationType.ADWORDS]: 'Adwords',
	[IntegrationType.CALL_COMMANDO]: 'Call Commando',
	[IntegrationType.FACEBOOK]: 'Facebook',
	[IntegrationType.GOOGLE_ANALYTICS]: 'Google Analytics',
};

interface IIntegration extends IEntity {
	account: Pick<Account, 'id' | 'name'>;
	name: string;
	type: IntegrationType;
	options: object;
}

export interface ICallCommandoIntegrationOptions {
	apiKey: string;
}

interface ICallCommandoIntegration extends IIntegration {
	type: IntegrationType.CALL_COMMANDO;
	options: ICallCommandoIntegrationOptions;
}

export interface IDigitalReportingIntegrationOptions {
	digitalReportAccountId: number;
}

interface IDigitalReportingIntegration extends IIntegration {
	type: IntegrationType.ADWORDS | IntegrationType.FACEBOOK;
	options: IDigitalReportingIntegrationOptions;
}

export interface IGoogleAnalyticsIntegrationOptions {
	profileId: string;
}

interface IGoogleAnalyticsIntegration extends IIntegration {
	type: IntegrationType.GOOGLE_ANALYTICS;
	options: IGoogleAnalyticsIntegrationOptions;
}

export type CallCommandoIntegration = Partial<ICallCommandoIntegration>;
export type DigitalReportingIntegration = Partial<IDigitalReportingIntegration>;
export type GoogleAnalyticsIntegration = Partial<IGoogleAnalyticsIntegration>;
export type Integration = CallCommandoIntegration | DigitalReportingIntegration | GoogleAnalyticsIntegration;

export type IntegrationPayload = Omit<Integration, 'id'> & {
	account: number;
};

export class IntegrationModel {
	public static list(query?: IQueryDocument, projection?: Projection, cancelToken?: CancelToken) {
		return client.get('/integrations', {
			cancelToken,
			params: {
				p: projection,
				q: query,
			},
		}).then(response => {
			response.data = response.data.map(IntegrationModel.denormalize);

			return response;
		}).catch(error => {
			throw error;
		});
	}

	public static create(data: IntegrationPayload, projection?: Projection) {
		return client.put('/integrations', data, {
			params: {
				p: projection,
			},
		}).then(response => {
			response.data = IntegrationModel.denormalize(response.data);

			return response;
		}).catch(error => {
			throw error;
		});
	}

	public static read(id: Id, projection?: Projection, cancelToken?: CancelToken) {
		return client.get<'/integrations/:id'>(`/integrations/${id}`, {
			cancelToken,
			params: {
				p: projection,
			},
		}).then(response => {
			response.data = IntegrationModel.denormalize(response.data);

			return response;
		}).catch(error => {
			throw error;
		});
	}

	public static update(id: Id, data: IntegrationPayload, projection?: Projection) {
		return client.patch<'/integrations/:id'>(`/integrations/${id}`, data, {
			params: {
				p: projection,
			},
		}).then(response => {
			response.data = IntegrationModel.denormalize(response.data);

			return response;
		}).catch(error => {
			throw error;
		});
	}

	public static delete(id: Id) {
		return client.delete<'/integrations/:id'>(`/integrations/${id}`);
	}

	private static denormalize = (integration: Integration) => {
		if (integration.type === IntegrationType.ADWORDS || integration.type === IntegrationType.FACEBOOK) {
			if (integration.options) {
				if (typeof integration.options.digitalReportAccountId === 'string') {
					integration.options.digitalReportAccountId = parseInt(
						integration.options.digitalReportAccountId,
						10,
					);
				}
			}
		}

		return integration;
	};
}
