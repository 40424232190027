import {CancelToken} from 'axios';
import {client} from '../client';
import {Id, IEntity} from '../Model';
import {IQueryDocument, Projection} from '../routes';
import {Pod} from './Pod';
import {Sheet} from './Sheet';
import {Sync} from './Sync';

interface IAccount extends IEntity {
	name: string;
	integrations: object[];
	pods: Pod[];
	sheets: Sheet[];
	syncs: Sync[];
}

export type Account = Partial<IAccount>;
export type AccountPayload = Pick<IAccount, 'name'> & {
	pods?: number[],
};

export class AccountModel {
	public static list(query?: IQueryDocument, projection?: Projection, cancelToken?: CancelToken) {
		return client.get('/accounts', {
			cancelToken,
			params: {
				p: projection,
				q: query,
			},
		});
	}

	public static create(data: AccountPayload, projection?: Projection) {
		return client.put('/accounts', data, {
			params: {
				p: projection,
			},
		});
	}

	public static read(id: Id, projection?: Projection, cancelToken?: CancelToken) {
		return client.get<'/accounts/:id'>(`/accounts/${id}`, {
			cancelToken,
			params: {
				p: projection,
			},
		});
	}

	public static update(id: Id, data: AccountPayload, projection?: Projection) {
		return client.patch<'/accounts/:id'>(`/accounts/${id}`, data, {
			params: {
				p: projection,
			},
		});
	}

	public static delete(id: Id) {
		return client.delete<'/accounts/:id'>(`/accounts/${id}`);
	}

	public static sync(id: Id, year: number) {
		return client.post<'/accounts/:id/spreadsheet/:year/:month?'>(`/accounts/${id}/spreadsheet/${year}`);
	}
}
