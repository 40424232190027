import {CancelToken} from 'axios';
import {client} from '../client';

interface IDigitalReportingAccount {
	id: number;
	name: string;
}

export type DigitalReportingAccount = Partial<IDigitalReportingAccount>;

export class DigitalReportingModel {
	public static getAccounts(cancelToken?: CancelToken) {
		return client.get('/digital-reporting/accounts', {
			cancelToken,
		});
	}
}
