import {Button, Classes, Dialog, FormGroup, Intent} from '@blueprintjs/core';
import {Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {Account} from '../../../Api/Models/Account';
import {range} from '../../../Utility/number';
import {IThemeAware, withTheme} from '../../Contexts/ThemeContext';
import {Theme} from '../../Theme/Theme';

interface IProps extends IThemeAware {
	account: Account;
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (account: Account, year: number) => Promise<any>;
}

interface IState {
	processing: boolean;
	year: number;
}

class SyncDialogComponent extends React.PureComponent<IProps, IState> {
	private readonly yearOptions: number[];

	public constructor(props: IProps) {
		super(props);

		const date = new Date();
		date.setMonth(date.getMonth() - 1);

		const year = date.getFullYear();

		this.yearOptions = range(year, year - 4);
		this.state = {
			processing: false,
			year,
		};
	}

	public render(): React.ReactNode {
		return (
			<Dialog
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isOpen={this.props.isOpen}
				onClose={this.props.onClose}
				title="Sync Options"
			>
				<div className={Classes.DIALOG_BODY}>
					<p>
						From the dropdown below, select a year that you would like to sync. This process may take a
						while (10 - 15 minutes), so please check the "Download" button later for a list of available
						spreadsheets.
					</p>

					<FormGroup label="Year">
						<Select
							onItemSelect={this.onYearSelect}
							selected={this.state.year}
							items={this.yearOptions}
							popoverProps={{
								targetClassName: 'full-width',
							}}
						/>
					</FormGroup>
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button disabled={this.state.processing} onClick={this.props.onClose}>
							Cancel
						</Button>

						<Button intent={Intent.PRIMARY} loading={this.state.processing} onClick={this.onSubmit}>
							Ok
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private onYearSelect = (year: number) => this.setState({
		year,
	});

	private onSubmit = () => {
		this.setState({
			processing: true,
		});

		this.props.onSubmit(this.props.account, this.state.year).then(() => this.setState({
			processing: false,
		}));
	};
}

export const SyncDialog = withTheme(SyncDialogComponent);
