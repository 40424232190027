export const compareFields = <T>(key: keyof T, a: T, b: T): number => {
	if (a === b)
		return 0;

	const aVal = a[key];
	const bVal = b[key];

	if (aVal === bVal)
		return 0;
	else if (aVal > bVal)
		return 1;

	return -1;
};
