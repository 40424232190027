import * as React from 'react';
import {Route} from 'react-router';
import {Switch} from '../../Navigation/Switch';
import {UserList} from './UserList';
import {UserEditor} from './UserEditor';

export const Users: React.FC<{}> = () => (
	<Switch>
		<Route path="/edit/users" exact={true} component={UserList} />
		<Route path="/edit/users/:user(\d+|new)" exact={true} component={UserEditor} />
	</Switch>
);
