import {Button, H2, InputGroup, Intent, Spinner} from '@blueprintjs/core';
import {ItemListPredicate} from '@blueprintjs/select';
import {Cell, MultiSelect, Row} from '@dbstudios/blueprintjs-components';
import Axios, {CancelTokenSource} from 'axios';
import * as React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {CancelReason} from '../../../Api/client';
import {IConstraintViolations, isConstraintViolationError} from '../../../Api/Error';
import {AccountModel, AccountPayload} from '../../../Api/Models/Account';
import {Pod, PodModel} from '../../../Api/Models/Pod';
import {toaster} from '../../../toaster';
import {LinkButton} from '../../Navigation/LinkButton';
import {ValidationAwareFormGroup} from '../../ValidationAwareFormGroup';
import {BreadcrumbItems, EditorBreadcrumbs} from '../EditorBreadcrumbs';
import {podSorter} from '../Pods/PodList';

interface IRouteProps {
	account: string;
}

interface IAccountEditorProps extends RouteComponentProps<IRouteProps> {
}

interface IAccountEditorState {
	allPods: Pod[];
	loading: boolean;
	name: string;
	newAccount: boolean;
	pods: Pod[];
	redirect: boolean;
	saving: boolean;
	violations: IConstraintViolations;
}

class AccountEditorComponent extends React.PureComponent<IAccountEditorProps, IAccountEditorState> {
	public state: Readonly<IAccountEditorState> = {
		allPods: null,
		loading: true,
		name: '',
		newAccount: false,
		pods: [],
		redirect: false,
		saving: false,
		violations: {},
	};

	private cancelSource: CancelTokenSource = null;

	public componentDidMount(): void {
		this.cancelSource = Axios.CancelToken.source();

		const allPodsPromise = PodModel.list(null, {
			id: true,
			name: true,
		}, this.cancelSource.token).then(response => {
			const allPods = response.data.sort(podSorter);

			this.setState({
				allPods,
			});

			return allPods;
		});

		const idParam = this.props.match.params.account;

		if (idParam === 'new') {
			this.setState({
				loading: false,
				newAccount: true,
			});

			allPodsPromise.then(() => this.cancelSource = null);

			return;
		}

		AccountModel.read(idParam, {
			name: true,
			'pods.id': true,
			'pods.name': true,
		}, this.cancelSource.token).then(response => {
			this.setState({
				loading: false,
				name: response.data.name,
				pods: response.data.pods,
			});

			allPodsPromise.then(allPods => {
				const podIds = response.data.pods.map(pod => pod.id);
				const pods: Pod[] = [];

				for (const pod of allPods) {
					if (podIds.indexOf(pod.id) !== -1)
						pods.push(pod);
				}

				this.setState({
					pods,
				});

				this.cancelSource = null;
			});
		});
	}

	public componentWillUnmount(): void {
		if (this.cancelSource)
			this.cancelSource.cancel(CancelReason.UNMOUNT);
	}

	public render(): React.ReactNode {
		if (this.state.loading)
			return <Spinner intent={Intent.PRIMARY} />;
		else if (this.state.redirect)
			return <Redirect to="/edit/accounts" />;

		return (
			<>
				<EditorBreadcrumbs
					items={[
						BreadcrumbItems.accounts(),
						{
							text: this.state.name || 'No Name',
						},
					]}
				/>

				<H2>{this.state.name.length ? this.state.name : <em>No Name</em>}</H2>

				<form>
					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup
								label="Account Name"
								labelFor="name"
								violations={this.state.violations}
							>
								<InputGroup name="name" onChange={this.onNameChange} value={this.state.name} />
							</ValidationAwareFormGroup>
						</Cell>

						<Cell size={6}>
							<ValidationAwareFormGroup label="Pods" labelFor="pods" violations={this.state.violations}>
								<MultiSelect
									itemListPredicate={this.onPodListFilter}
									items={this.state.allPods}
									itemTextRenderer={this.renderPodText}
									loading={this.state.allPods === null}
									onClear={this.onPodsClear}
									onItemDeselect={this.onPodDeselect}
									onItemSelect={this.onPodSelect}
									popoverProps={{
										targetClassName: 'full-width',
									}}
									selected={this.state.pods}
									virtual={true}
								/>
							</ValidationAwareFormGroup>
						</Cell>
					</Row>

					<Row>
						<Cell size={10}>
							<LinkButton
								buttonProps={{disabled: this.state.newAccount}}
								to={`/edit/accounts/${this.props.match.params.account}/integrations`}
							>
								{this.state.newAccount ? 'No Integrations' : 'View Integrations'}
							</LinkButton>
						</Cell>

						<Cell className="text-right" size={1}>
							<LinkButton to="/edit/accounts" buttonProps={{fill: true, loading: this.state.saving}}>
								Cancel
							</LinkButton>
						</Cell>

						<Cell className="text-right" size={1}>
							<Button intent={Intent.PRIMARY} fill={true} onClick={this.save} loading={this.state.saving}>
								Save
							</Button>
						</Cell>
					</Row>
				</form>
			</>
		);
	}

	private renderPodText = (pod: Pod) => pod.name;

	private onPodsClear = () => this.setState({
		pods: [],
	});

	private onPodDeselect = (target: Pod) => this.setState({
		pods: this.state.pods.filter(pod => pod !== target),
	});

	private onPodSelect = (pod: Pod) => this.setState({
		pods: [...this.state.pods, pod],
	});

	private onPodListFilter: ItemListPredicate<Pod> = (query, items) => {
		query = query.toLowerCase();

		return items.filter(item => item.name.toLowerCase().indexOf(query) !== -1);
	};

	private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		name: event.currentTarget.value,
	});

	private save = (event?: React.SyntheticEvent<any>) => {
		if (event)
			event.preventDefault();

		if (this.state.saving)
			return;

		this.setState({
			saving: true,
			violations: {},
		});

		const payload: AccountPayload = {
			name: this.state.name,
			pods: this.state.pods.map(pod => pod.id),
		};

		const idParam = this.props.match.params.account;

		let promise: Promise<{}>;

		if (idParam === 'new')
			promise = AccountModel.create(payload);
		else
			promise = AccountModel.update(idParam, payload);

		promise.then(() => {
			toaster.show({
				intent: Intent.SUCCESS,
				message: `${this.state.name} ${idParam === 'new' ? 'created' : 'saved'} successfully.`,
			});

			this.setState({
				redirect: true,
			});
		}).catch((error: Error) => {
			toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				saving: false,
			});

			if (isConstraintViolationError(error)) {
				this.setState({
					violations: error.context.violations,
				});
			}
		});
	};
}

export const AccountEditor = withRouter(AccountEditorComponent);
