import * as React from 'react';
import {Redirect, Route, RouteComponentProps, RouteProps} from 'react-router';
import {tokenStore} from '../Api/client';

type RenderCallback = (props: RouteComponentProps<any>) => React.ReactNode;
type RendererProps = RouteComponentProps<any> & RouteProps;

export const PrivateRoute: React.FC<RouteProps> = ({
	component,
	render,

	// tslint:disable-next-line:trailing-comma
	...routeProps
}) => {
	const doRender = (Component?: React.ComponentType, renderFunc?: RenderCallback) => (props: RendererProps) => {
		if (!Component && !renderFunc)
			return null;

		if (tokenStore.isAuthenticated())
			return Component ? <Component {...props} /> : renderFunc(props);

		const to = {
			pathname: '/login',
			state: {
				from: props.location,
			},
		};

		return <Redirect to={to} />;
	};

	return <Route {...routeProps} render={doRender(component, render)} />;
};
