import * as React from 'react';
import {Subtract} from 'utility-types';
import {getDisplayName} from '../../Utility/component';
import {Theme} from '../Theme/Theme';

export const ThemeContext = React.createContext(Theme.DARK);

export interface IThemeAware {
	theme: Theme;
}

export const withTheme = <P extends IThemeAware, R =
	Subtract<P, IThemeAware>>(Component: React.ComponentType<P>): React.FC<R> => {
	const hoc: React.FC<R> = props => (
		<ThemeContext.Consumer>
			{theme => <Component {...props as any} theme={theme} />}
		</ThemeContext.Consumer>
	);

	hoc.displayName = `WithTheme(${getDisplayName(Component)}`;

	return hoc;
};

export const ThemeChangerContext = React.createContext((theme: Theme): void => {
	throw new Error('ThemeChangerContext used without a parent provider');
});

export interface IThemeChangerAware {
	onThemeChange: (theme: Theme) => void;
}

export const withThemeChanger = <P extends IThemeChangerAware, R =
	Subtract<P, IThemeChangerAware>>(Component: React.ComponentType<P>): React.FC<R> => {
	const hoc: React.FC<R> = props => (
		<ThemeChangerContext.Consumer>
			{onThemeChange => <Component onThemeChange={onThemeChange} {...props as any} />}
		</ThemeChangerContext.Consumer>
	);

	hoc.displayName = `WithThemeChange(${getDisplayName(Component)}`;

	return hoc;
};
