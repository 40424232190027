import {FormGroup} from '@blueprintjs/core';
import {Cell, Row, Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {CallCommandoAccount, CallCommandoModel} from '../../../../Api/Models/CallCommando';
import {ICallCommandoIntegrationOptions} from '../../../../Api/Models/Integration';
import {createEntitySorter} from '../../EntityList';

const accountSorter = createEntitySorter<CallCommandoAccount>('name');

interface IProps {
	options: ICallCommandoIntegrationOptions;
	onChange: (options: ICallCommandoIntegrationOptions) => void;
}

interface IState {
	account: CallCommandoAccount;
	accountOptions: CallCommandoAccount[];
}

export class CallCommandoOptionsEditor extends React.PureComponent<IProps, IState> {
	public state: Readonly<IState> = {
		account: null,
		accountOptions: null,
	};

	public componentDidMount(): void {
		CallCommandoModel.getAccounts().then(response => this.setState({
			account: this.findSelectedAccount(response.data),
			accountOptions: response.data.sort(accountSorter),
		}));
	}

	public componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (this.props.options === prevProps.options)
			return;

		this.setState({
			account: this.findSelectedAccount(),
		});
	}

	public render(): React.ReactNode {
		return (
			<>
				<Row>
					<Cell size={6}>
						<FormGroup label="Call Commando API Key" labelFor="apiKey">
							<Select
								itemKey="apiKey"
								itemListPredicate={this.onAccountFilter}
								items={this.state.accountOptions}
								itemTextRenderer={this.renderAccountText}
								loading={this.state.accountOptions === null}
								onItemSelect={this.onAccountSelect}
								popoverProps={{
									targetClassName: 'full-width',
								}}
								selected={this.state.account}
								virtual={true}
							/>
						</FormGroup>
					</Cell>
				</Row>
			</>
		);
	}

	private renderAccountText = (account: CallCommandoAccount) => account.name;

	private onAccountFilter = (query: string, accounts: CallCommandoAccount[]) => {
		query = query.toLowerCase();

		return accounts.filter(account => account.name.toLowerCase().indexOf(query) !== -1);
	};

	private onAccountSelect = (account: CallCommandoAccount) => {
		this.setState({
			account,
		});

		this.props.onChange({
			apiKey: account.apiKey,
		});
	};

	private findSelectedAccount = (accounts?: CallCommandoAccount[]) => {
		if (!this.props.options.apiKey)
			return null;

		return (accounts || this.state.accountOptions || [])
			.find(account => account.apiKey === this.props.options.apiKey) || null;
	};
}
