import * as React from 'react';
import {Route} from 'react-router';
import {Switch} from '../../Navigation/Switch';
import {PodEditor} from './PodEditor';
import {PodList} from './PodList';

export const Pods: React.FC<{}> = () => (
	<Switch>
		<Route path="/edit/pods" exact={true} component={PodList} />
		<Route path="/edit/pods/:pod(\d+|new)" exact={true} component={PodEditor} />
	</Switch>
);
