import {FormGroup} from '@blueprintjs/core';
import {Cell, Row, Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {DigitalReportingAccount, DigitalReportingModel} from '../../../../Api/Models/DigitalReporting';
import {IDigitalReportingIntegrationOptions} from '../../../../Api/Models/Integration';
import {createEntitySorter} from '../../EntityList';

const accountSorter = createEntitySorter<DigitalReportingAccount>('name');

interface IProps {
	options: IDigitalReportingIntegrationOptions;
	onChange: (options: IDigitalReportingIntegrationOptions) => void;
}

interface IState {
	account: DigitalReportingAccount;
	accountOptions: DigitalReportingAccount[];
}

export class DigitalReportingOptionsEditor extends React.PureComponent<IProps, IState> {
	public state: Readonly<IState> = {
		account: null,
		accountOptions: null,
	};

	public componentDidMount(): void {
		DigitalReportingModel.getAccounts().then(response => this.setState({
			account: this.findSelectedAccount(response.data),
			accountOptions: response.data.sort(accountSorter),
		}));
	}

	public componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (this.props.options === prevProps.options)
			return;

		this.setState({
			account: this.findSelectedAccount(),
		});
	}

	public render(): React.ReactNode {
		return (
			<>
				<Row>
					<Cell size={6}>
						<FormGroup label="Digital Reporting Account" labelFor="digitalReportAccountId">
							<Select
								itemKey="id"
								itemListPredicate={this.onAccountFilter}
								items={this.state.accountOptions}
								itemTextRenderer={this.renderAccountText}
								loading={this.state.accountOptions === null}
								onItemSelect={this.onAccountSelect}
								popoverProps={{
									targetClassName: 'full-width',
								}}
								selected={this.state.account}
								virtual={true}
							/>
						</FormGroup>
					</Cell>
				</Row>
			</>
		);
	}

	private renderAccountText = (account: DigitalReportingAccount) => account.name;

	private onAccountFilter = (query: string, accounts: DigitalReportingAccount[]) => {
		query = query.toLowerCase();

		return accounts.filter(account => account.name.toLowerCase().indexOf(query) !== -1);
	};

	private onAccountSelect = (account: DigitalReportingAccount) => {
		this.setState({
			account,
		});

		this.props.onChange({
			digitalReportAccountId: account.id,
		});
	};

	private findSelectedAccount = (accounts?: DigitalReportingAccount[]) => {
		if (!this.props.options.digitalReportAccountId)
			return null;

		return (accounts || this.state.accountOptions || [])
			.find(account => account.id === this.props.options.digitalReportAccountId) || null;
	};
}
