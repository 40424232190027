import {Button, H3} from '@blueprintjs/core';
import {Cell, IColumn, Row, Table} from '@dbstudios/blueprintjs-components';
import {debounce} from 'debounce';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {Entity} from '../../Api/Model';
import {compareFields} from '../../Utility/entity';
import {SearchInput} from '../SearchInput';
import {RefreshButton} from './RefreshButton';
import {RowControls} from './RowControls';

export const createEntityFilter = <T extends object>(key: keyof T) => (record: T, search: string) => {
	const value = record[key];

	if (typeof value !== 'string')
		throw new Error('This function can only operate on string values');

	return value.toLowerCase().indexOf(search) > -1;
};

export const createEntitySorter = <T extends object>(key: keyof T) => (a: T, b: T) => compareFields(key, a, b);

interface IProps<T extends Entity> {
	/**
	 * The base path to use for control button routing.
	 */
	basePath: string;

	/**
	 * An array of columns to render. The last column will always be the controls column, and does not need to be
	 * specified.
	 */
	columns: Array<IColumn<T>>;

	/**
	 * The entities to render in the list.
	 */
	entities: T[];

	/**
	 * A React node to render in place of the entity list of the list has no items in it. This node is used both when
	 * the API returns no objects, as well as when there are no results for a particular search string.
	 */
	noDataPlaceholder: React.ReactNode;

	/**
	 * The title to display above the component.
	 */
	title: string;

	/**
	 * If `true`, all search methods will be debounced with a delay of 200ms.
	 */
	debounce?: boolean;

	/**
	 * Any additional controls to render above the table. These are rendered to the left of the "Add New" button,
	 * below the title and search bar.
	 */
	extraControls?: React.ReactNode;

	/**
	 * If specified, the loading state of the table is considered controlled. Loading of the entity list will be
	 * delayed until the loading property is `true`. This is useful if you need to pass additional arguments to the
	 * {@see query} or {@see projection} properties that aren't available until some other asynchronous action is
	 * completed.
	 */
	loading?: boolean;

	/**
	 * A callback to invoke when an entity is deleted from the list.
	 */
	onDelete?: (entity: T) => Promise<void>;

	/**
	 * A callback to invoke when the refresh button is clicked. If this property is not provided, a refresh button
	 * will not be rendered.
	 */
	onRefresh?: () => void;

	/**
	 * If provided, the component returned by this callback will be rendered in addition to the normal row controls.
	 */
	rowControls?: (record: T) => React.ReactNode;

	/**
	 * Sets the width of the row controls column (default 200px).
	 */
	rowControlsWidth?: number | string;
}

interface IState<T extends Entity> {
	columns: Array<IColumn<T>>;
	search: string;
}

export class EntityList<T extends Entity> extends React.PureComponent<IProps<T>, IState<T>> {
	public static defaultProps: Partial<IProps<any>> = {
		debounce: true,
		rowControlsWidth: 200,
	};

	public constructor(props: IProps<T>) {
		super(props);

		this.state = {
			columns: [
				...props.columns,
				{
					align: 'right',
					render: record => (
						<>
							{this.props.rowControls && this.props.rowControls(record)}

							<RowControls
								entity={record}
								editPath={`${props.basePath}/${record.id}`}
								onDelete={props.onDelete}
							/>
						</>
					),
					style: {
						width: this.props.rowControlsWidth,
					},
					title: '',
				},
			],
			search: '',
		};
	}

	public render(): React.ReactNode {
		return (
			<>
				<div style={{display: 'flex'}}>
					<div style={{flex: 2}}>
						<H3>
							{this.props.title}

							{this.props.onRefresh && <RefreshButton onRefresh={this.props.onRefresh} />}
						</H3>
					</div>

					<div style={{flex: 1}}>
						<SearchInput
							onSearch={
								this.props.debounce ? this.onSearchInputChangeDebounced : this.onSearchInputChange
							}
						/>
					</div>
				</div>

				<Row>
					<Cell size={10}>
						{this.props.extraControls}
					</Cell>

					<Cell size={2} className="text-right">
						<Link to={`${this.props.basePath}/new`} className="plain-link">
							<Button icon="plus">
								Add New
							</Button>
						</Link>
					</Cell>
				</Row>

				<div style={{marginTop: 15}}>
					<Table
						dataSource={this.props.entities}
						columns={this.state.columns}
						fullWidth={true}
						htmlTableProps={{
							interactive: true,
							striped: true,
						}}
						loading={this.props.loading}
						noDataPlaceholder={this.props.noDataPlaceholder}
						pageSize={20}
						rowKey="id"
						searchText={this.state.search}
					/>
				</div>
			</>
		);
	}

	private onSearchInputChange = (search: string) => this.setState({
		search: search.toLowerCase(),
	});

	// tslint:disable-next-line:member-ordering
	private onSearchInputChangeDebounced = debounce(this.onSearchInputChange, 200);

	public static ofType<T extends Entity>() {
		return EntityList as new (props: IProps<T>) => EntityList<T>;
	}
}
