import * as React from 'react';
import {
	ICallCommandoIntegrationOptions,
	IDigitalReportingIntegrationOptions,
	IGoogleAnalyticsIntegrationOptions,
	IntegrationType,
} from '../../../Api/Models/Integration';
import {CallCommandoOptionsEditor} from './Options/CallCommandoOptionsEditor';
import {DigitalReportingOptionsEditor} from './Options/DigitalReportingOptionsEditor';
import {GoogleAnalyticsOptionsEditor} from './Options/GoogleAnalyticsOptionsEditor';

interface IProps {
	type: IntegrationType;
	options: object;
	onChange: (options: object) => void;
}

export const IntegrationOptionsEditor: React.FC<IProps> = props => {
	let content: React.ReactNode = null;

	if (props.type === IntegrationType.CALL_COMMANDO) {
		content = (
			<CallCommandoOptionsEditor
				options={props.options as ICallCommandoIntegrationOptions}
				onChange={props.onChange}
			/>
		);
	} else if (props.type === IntegrationType.ADWORDS || props.type === IntegrationType.FACEBOOK) {
		content = (
			<DigitalReportingOptionsEditor
				options={props.options as IDigitalReportingIntegrationOptions}
				onChange={props.onChange}
			/>
		);
	} else if (props.type === IntegrationType.GOOGLE_ANALYTICS) {
		content = (
			<GoogleAnalyticsOptionsEditor
				options={props.options as IGoogleAnalyticsIntegrationOptions}
				onChange={props.onChange}
			/>
		);
	}

	return (
		<div>
			{content}
		</div>
	);
};
