import {Button, H2, InputGroup, Intent, Spinner} from '@blueprintjs/core';
import {Cell, Row, Select} from '@dbstudios/blueprintjs-components';
import {Projection} from '@dbstudios/query-document-client';
import Axios, {CancelTokenSource} from 'axios';
import * as React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {CancelReason} from '../../../Api/client';
import {IConstraintViolations, isConstraintViolationError} from '../../../Api/Error';
import {AccountModel} from '../../../Api/Models/Account';
import {
	IntegrationModel,
	IntegrationPayload,
	integrationsNames,
	IntegrationType,
} from '../../../Api/Models/Integration';
import {toaster} from '../../../toaster';
import {LinkButton} from '../../Navigation/LinkButton';
import {ValidationAwareFormGroup} from '../../ValidationAwareFormGroup';
import {BreadcrumbItems, EditorBreadcrumbs} from '../EditorBreadcrumbs';
import {IntegrationOptionsEditor} from './IntegrationOptionsEditor';

interface IRouteProps {
	integration: string;
	account: string;
}

interface IIntegrationEditorProps extends RouteComponentProps<IRouteProps> {
}

interface IIntegrationEditorState {
	accountName: string;
	loading: boolean;
	name: string;
	options: object;
	redirect: boolean;
	saving: boolean;
	type: IntegrationType;
	violations: IConstraintViolations;
}

class IntegrationEditorComponent extends React.PureComponent<IIntegrationEditorProps, IIntegrationEditorState> {
	public state: Readonly<IIntegrationEditorState> = {
		accountName: null,
		loading: true,
		name: '',
		options: {},
		redirect: false,
		saving: false,
		type: null,
		violations: {},
	};

	private cancelSource: CancelTokenSource = null;

	public componentDidMount(): void {
		const idParam = this.props.match.params.integration;
		const account = this.props.match.params.account;

		if (idParam === 'new') {
			AccountModel.read(account).then(response => {
				this.setState({
					accountName: response.data.name,
					loading: false,
				});
			});

			return;
		}

		this.cancelSource = Axios.CancelToken.source();

		IntegrationModel.read(idParam).then(response => {
			this.setState({
				accountName: response.data.account.name,
				loading: false,
				name: response.data.name,
				options: response.data.options,
				type: response.data.type,
			});

			this.cancelSource = null;
		});
	}

	public componentWillUnmount(): void {
		if (this.cancelSource)
			this.cancelSource.cancel(CancelReason.UNMOUNT);
	}

	public render(): React.ReactNode {
		if (this.state.loading)
			return <Spinner intent={Intent.PRIMARY} />;
		else if (this.state.redirect)
			return <Redirect to={`/edit/accounts/${this.props.match.params.account}/integrations`} />;

		return (
			<>
				<EditorBreadcrumbs
					items={[
						BreadcrumbItems.accounts(),
						BreadcrumbItems.account(this.props.match.params.account, this.state.accountName),
						BreadcrumbItems.integrations(this.props.match.params.account),
						{
							text: this.state.name || 'No Name',
						},
					]}
				/>

				<H2>{this.state.name || <em>No Name</em>}</H2>

				<form>
					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup
								label="Integration Type"
								labelFor="type"
								violations={this.state.violations}
							>
								<Select
									disabled={this.props.match.params.integration !== 'new'}
									filterable={false}
									items={[
										IntegrationType.ADWORDS,
										IntegrationType.CALL_COMMANDO,
										IntegrationType.FACEBOOK,
										IntegrationType.GOOGLE_ANALYTICS,
									]}
									itemTextRenderer={this.renderTypeValue}
									onItemSelect={this.onTypeSelect}
									popoverProps={{
										targetClassName: 'full-width',
									}}
									selected={this.state.type}
								/>
							</ValidationAwareFormGroup>
						</Cell>

						<Cell size={6}>
							<ValidationAwareFormGroup
								label="Integration Name"
								labelFor="name"
								violations={this.state.violations}
							>
								<InputGroup name="name" onChange={this.onNameChange} value={this.state.name} />
							</ValidationAwareFormGroup>
						</Cell>
					</Row>

					<IntegrationOptionsEditor
						type={this.state.type}
						options={this.state.options}
						onChange={this.onOptionsChange}
					/>

					<Row align="end">
						<Cell size={1}>
							<LinkButton
								to={`/edit/accounts/${this.props.match.params.account}/integrations`}
								buttonProps={{fill: true, loading: this.state.saving}}
							>
								Cancel
							</LinkButton>
						</Cell>

						<Cell size={1}>
							<Button intent={Intent.PRIMARY} fill={true} onClick={this.save} loading={this.state.saving}>
								Save
							</Button>
						</Cell>
					</Row>
				</form>
			</>
		);
	}

	private renderTypeValue = (type: IntegrationType) => integrationsNames[type];

	private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		name: event.currentTarget.value,
	});

	private onOptionsChange = (options: object) => this.setState({
		options,
	});

	private onTypeSelect = (type: IntegrationType) => this.setState({
		name: integrationsNames[type],
		type,
	});

	private save = (event?: React.SyntheticEvent<any>) => {
		if (event)
			event.preventDefault();

		if (this.state.saving)
			return;

		this.setState({
			saving: true,
			violations: {},
		});

		const payload = {
			account: parseInt(this.props.match.params.account, 10),
			name: this.state.name,
			options: this.state.options,
			type: this.state.type,
		} as IntegrationPayload;

		const projection: Projection = {
			name: true,
		};

		const idParam = this.props.match.params.integration;
		let promise: Promise<unknown>;

		if (idParam === 'new')
			promise = IntegrationModel.create(payload, projection);
		else
			promise = IntegrationModel.update(parseInt(idParam, 10), payload, projection);

		promise.then(() => {
			toaster.show({
				intent: Intent.SUCCESS,
				message: `${this.state.name} ${idParam === 'new' ? 'created' : 'saved'} successfully.`,
			});

			this.setState({
				redirect: true,
			});
		}).catch((error: Error) => {
			toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				saving: false,
			});

			if (isConstraintViolationError(error)) {
				this.setState({
					violations: error.context.violations,
				});
			}
		});
	};
}

export const IntegrationEditor = withRouter(IntegrationEditorComponent);
