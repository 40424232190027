import 'dotenv/config';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';
import './index.scss';
import {Layout} from './Layout';

if (process.env.GA_TRACKING_ID)
	ReactGA.initialize(process.env.GA_TRACKING_ID);

ReactDOM.render(<Layout />, document.getElementById('application'));
